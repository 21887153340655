<template>
    <div>
        <div class="modal" v-if="modelValue" :class="{active: modelValue}" @click.self="closeModal">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Signatures</h1>
                    <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="section_wpr">
                        <h3>Signature List<button type="button" class="add_btn m-0" @click="handleAddSignature"><i class="fas fa-plus"></i>Add Signature</button></h3>
                    </div>
                    <div class="tempcard">
                        <div class="load_more" v-if="loader">
                            <img src="@/assets/images/load_more.gif" alt="">
                            Loading
                        </div>
                        <ul class="mt-1" v-if="!loader">
                            <li v-for="(signature, s) in signatures.data" :key="s">
                                <div class="tempcard_details">
                                    <h5>{{ signature.signature_name }}</h5>
                                    <p><img src="@/assets/images/client.svg" alt="">{{ signature.company_name }}</p>
                                </div>
                                <span class="view px-2" @click="handleDeleteSignature(signature.id)"><i class="fas fa-trash-alt text-danger p-0"></i></span>
                                <span class="view m-0" @click="handleEditSignature(signature)"><i class="fas fa-edit"></i></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="pagination" v-show="signatures.total">
                    <pagination :range-size="0" v-model="params.page" :pages="signatures.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
        <add-signature v-model="addSignature" :edit-signature="editSignature" :handle-close="handleAddSignatureClose" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const AddSignature = defineAsyncComponent(() => import('@/pages/admin-setting/components/signature/AddSignature'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        data() {
            return {
                addSignature: false,
                params: {
                    page: 1,
                    per_page: 5
                },
                editSignature: {},
            }
        },

        props: {
            modelValue: Boolean
        },

        emits: ['update:modelValue', ],

        components: {
            AddSignature,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    setTimeout(function () {
                        vm.getSignatureList(vm.params);
                    }, 1000);
                }else if(!value && this.addSignature){
                    document.body.classList.add('modal-open');
                }else{
                    document.body.classList.remove('modal-open');
                }
            },
            addSignature(val){
                if (!val) {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            signatures: state => state.signatureModule.allSignatures,
            loader: state => state.signatureModule.signatureLoader,
        }),

        methods: {
            ...mapActions({
                getSignatureList: 'signatureModule/getSignatureList',
                deleteSignature: 'signatureModule/deleteSignature',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleAddSignature () {
                const vm = this;

                vm.editSignature = {};
                vm.closeModal();
                vm.addSignature = true;
            },

            handlePagination (page) {
                const vm = this;
                vm.getSignatureList(vm.params);
            },

            handleEditSignature (signature) {
                const vm = this;

                vm.editSignature = signature;
                vm.closeModal();
                vm.addSignature = true;
            },

            handleDeleteSignature (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', 'You will not be able to recover this email signature.', 'Yes', false);

                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        vm.deleteSignature(id).then((result) => {
                            vm.params.page = 1;
                            vm.getSignatureList(vm.params);
                        });
                    }
                });
            },

            handleAddSignatureClose () {
                const vm = this;

                vm.$emit('update:modelValue', true);
            },
        }
    }
</script>

<style scoped>
    .pagination{
        padding: 15px 40px;
        background: #fff;
        border-top: 1px solid #eee;
    }
</style>
